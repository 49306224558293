/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const {Table} = Object.assign({}, _provideComponents(), props.components);
  if (!Table) _missingMdxReference("Table", true);
  return React.createElement(Table, null, React.createElement("thead", null, React.createElement("tr", null, React.createElement("th", null, "Faroese"), "\n", React.createElement("th", null, "Transliteration"), "\n", React.createElement("th", null, "English"))), React.createElement("tbody", null, React.createElement("tr", null, React.createElement("td", null, "norður"), "\n", React.createElement("td", null, "nothur"), "\n", React.createElement("td", null, "North")), React.createElement("tr", null, React.createElement("td", null, "suður"), "\n", React.createElement("td", null, "suthur"), "\n", React.createElement("td", null, "South")), React.createElement("tr", null, React.createElement("td", null, "eystur"), "\n", React.createElement("td", null, "estur"), "\n", React.createElement("td", null, "East")), React.createElement("tr", null, React.createElement("td", null, "vestur"), "\n", React.createElement("td", null, "vestur"), "\n", React.createElement("td", null, "West")), React.createElement("tr", null, React.createElement("td", null, "landnyrðingur"), "\n", React.createElement("td", null, "landnurthingur"), "\n", React.createElement("td", null, "North East")), React.createElement("tr", null, React.createElement("td", null, "landsynningur"), "\n", React.createElement("td", null, "landsiningur"), "\n", React.createElement("td", null, "South East")), React.createElement("tr", null, React.createElement("td", null, "útsynningur"), "\n", React.createElement("td", null, "utsiningur"), "\n", React.createElement("td", null, "South West")), React.createElement("tr", null, React.createElement("td", null, "útnyrðingur"), "\n", React.createElement("td", null, "utnyrthingur"), "\n", React.createElement("td", null, "North West"))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
